<template>
    <div>
        <div class="table_content">
            <!-- 表格内容 -->
            <commonTitle></commonTitle>
            <el-card>
                <el-table
                        :data="tableData"
                        v-loading="loading"
                        style="width:100%"
                        element-loading-text="拼命加载中"
                        
                        :stripe="true"
                >
                    <el-table-column
                            prop="appropriationPeriod"
                            label="拨款批次"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="prepaymentDate"
                            label="预付款日期"
                            :formatter="formatterPrepaymentDate"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="prepaymentAmount"
                            label="预付金额"
                            width="120px">
                    </el-table-column>
                    <el-table-column
                            prop="prepaymentRatio"
                            label="预付比例"
                            :formatter="formatterPrepaymentRatio"
                    >
                    </el-table-column>
                </el-table>
                <el-row>
                    <el-pagination
                            background
                            style="margin-top: 50px"
                            layout="prev, pager, next"
                            :page-size="15"
                            @current-change="handleCurrentChange"
                            :total="totalNum">
                    </el-pagination>
                    <el-button @click="back" class="btn" size="small">上一步</el-button>
                </el-row>
            </el-card>
        </div>
    </div>
</template>

<script>
    import commonTitle from "../table/commonTitle";

    export default {
        name: "sharePlan",
        components: {
            commonTitle
        },
        data() {
            return {
                tableData: [],//表格数据集
                totalNum: 15,
                form: {
                    name: "",
                    region: '',
                },
                loading: false,
                url: this.$Config.base_server
            };
        }, mounted() {
            this.common()
        }, methods: {
            common(currentPage = 0) {
                //初始化分摊数据
                const that = this;
                that.loading = true;
                const data = {
                    access_token: localStorage.getItem("token"),
                    page: currentPage,
                    limit: 15,
                    shareId: that.$route.query.shareId,
                    houseMoney: that.$route.query.houseMoney
                }
                $.ajax({
                    url: that.url + "/api-public/repairSharePlane/findOpeAppropriationPlanByShareId",
                    data: data,
                    contentType: "application/json",
                    type: "get",
                    success: function (res) {
                        if (res.code == 0) {
                            that.loading = false;
                            that.totalNum = res.count;
                            that.tableData = res.data;
                        }
                    },
                    error: function (res) {
                        that.loading = false;
                    }
                });
            }, sear(currentPage = 0) {
                this.loading = true;
                var _this = this;
                var da = {
                    houseNum: _this.form.name,
                    access_token: localStorage.getItem("token"),
                    page: currentPage,
                    limit: 15,
                    searchKey: 'unitId',
                    searchValue: JSON.parse(localStorage.getItem("unitData")).unitId
                };
                $.ajax({
                    url: _this.url + "/api-public/opehouse/list",
                    data: da,
                    contentType: 'application/json',
                    type: 'get',
                    success: function (result) {
                        _this.loading = false;
                        _this.tableData = result.data;
                    }
                });
            },
            back() {
                //返回按钮
                window.history.back(-1);
            },
            handleSizeChange(val) {
                console.log('每页${val}条');
                this.pagesize = val;
            },
            handleCurrentChange(val) {
                console.log('当前页' + val);
                this.currpage = val;
                this.common(val);
            }, formatterPrepaymentDate(row) {
                //格式化时间
                //格式化时间
                let date = new Date(row.prepaymentDate);
                let month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
                let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
                return date.getFullYear() + "-" + month + "-" + day;
            }, formatterPrepaymentRatio(row) {
                //格式化预付款比例
                return Math.round(row.prepaymentRatio * 100) / 100 + "%";

            }
        }
    }
</script>

<style scoped>
    .btn {
        float: right;
        transform: translateY(-34px);
    }
</style>